<style lang="scss" scoped></style>

<template>
  <a-spin size="large" :spinning="spinning">
    <div class="consultingManagement">
      <ds-header title="行业分类管理"></ds-header>
      <a-form layout="inline">
        <a-form-item label="行业分类名称">
          <a-input v-model="search.industry_name" :maxLength="50" placeholder="请输入行业分类名称" />
        </a-form-item>
        <span>
          <a-button type="primary" style="margin-right: 20px;" @click="getList('search')">
            <a-icon type="search" />
            查询
          </a-button>
          <a-button @click="() => {
            search.industry_name = '';
            pagination.current = 1;
            pagination.pageSize = 10;
            getList();
          }
            ">
            重置
          </a-button>
        </span>
      </a-form>
      <div>
        <a-button type="primary" @click="getindustryTree">
          <a-icon type="plus" />
          添加
        </a-button>
      </div>
      <div style="margin-top: 10px">
        <a-table bordered :rowKey="(record, index) => record.industry_id" :childrenColumnName="'child_list'"
          :columns="table.columns" :data-source="table.data" @change="pageChange" :pagination="pagination" :scroll="{ x: 'max-content' }">
          <span slot="action" slot-scope="text, record">
            <a-button type="link" @click="handleEdit(record)">编辑</a-button>
            <!-- <a-button type="link" @click="handleDelMenu(record)">删除</a-button> -->
            <deletebut tipsTitle="删除分类" tipsContent="确认要删除该分类吗？" @deleteBut="handleDelMenu(record)" :record="record">
            </deletebut>
          </span>
          <span slot="update_name" slot-scope="text, record">
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>{{ record.update_name }}</span>
              </template>
              <span class="productName">{{ record.update_name }}</span>
            </a-tooltip>
          </span>
        </a-table>
      </div>
    </div>
    <a-modal v-model="dialog.showAdd || dialog.showEdit" :title="title" @cancel='handleAddClose' @ok="handleAddOk"
      :maskClosable="false">
      <a-form class="formModel" layout="horizontal">
        <a-row>
          <a-col>
            <a-form-item label="行业分类名称" class="d-flex">
              <a-tree-select v-model="parent_industry_id" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                style="width: 100%" :treeDefaultExpandAll="false"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="industryTree"
                placeholder="请选择父级行业分类" tree-default-expand-all :replaceFields="fieldNames2">
              </a-tree-select>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item label="行业分类名称" required class="d-flex">
              <a-input v-model="industry_name" :maxLength="20" style="width: 100%" placeholder="请输入行业分类名称" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>

  </a-spin>
</template>

<script type="text/babel">
/**
 * TODO fileOverview
 * --------------
 * @author xiaoF
 * @since 2021/6/1.
 */
import * as api from "@/api/enterprise/enterprise.js";
import moment from "moment";
import deletebut from "@/components/delete/index";

export default {
  name: "index",
  components: {
    deletebut
  },
  data() {
    return {
      title: '',
      industryTree: [],
      fieldNames2: { label: 'industry_name', value: 'industry_id', children: 'child_list' },
      industry_name: '',//添加或修改名称
      parent_industry_id: null,//添加或修改的父级id
      industry_id: '',//修改的分类id
      activeKey: 1,
      dialog: {
        showAdd: false,
        showEdit: false,
        id: "",
      },

      pagination: {
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total, range) => {
          return `共${total}条`
        }
      },
      search: {
        industry_name: ''
      },
      table: {
        columns: [
          {
            title: "行业分类名称",
            dataIndex: "industry_name",
            key: "industry_name",
            scopedSlots: { customRender: "industry_name" },
            width: 350,
            ellipsis: true,
          },
          {
            title: "最后修改人",
            dataIndex: "update_name",
            key: "update_name",
            width: 'auto',
            scopedSlots: { customRender: "update_name" },
          },
          {
            title: "最后修改时间",
            dataIndex: "update_at",
            key: "update_at",
            customRender: (text, row, index) => {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : '-';
            },
            width: 'auto',
          },
          {
            title: "创建人",
            dataIndex: "create_name",
            key: "create_name",
            width: 'auto',
          },
          {
            title: "创建时间",
            dataIndex: "create_at",
            key: "create_at",
            customRender: (text, row, index) => {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : '-';
            },
            width: 'auto',
          },
          {
            title: "操作",
            key: "action",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
            fixed: 'right',
            width: 200,
          },
        ],
        data: [],
      },
      spinning: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getindustryTree() {
      this.dialog.showAdd = true;
      this.title = '新增行业分类'
      let res = await api.getindustryTree()

      console.log(res.data, '============>');
      this.industryTree = res.data
    },
    handleAddOk(params) {
      if (!this.industry_name) {
        this.$message.error('请输入行业分类名称,最大20个字')
        return
      }
      if (this.dialog.showAdd) {
        api.addIndustryTree({ parent_industry_id: this.parent_industry_id, industry_name: this.industry_name }).then((res) => {
          console.log(res, '==============>');
          if (res.code === "200") {
            this.dialog.showAdd = false;
            this.$message.success("添加成功");
            this.getList();
            this.handleAddClose()
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        let obj = {
          parent_industry_id: this.parent_industry_id,
          industry_name: this.industry_name,
          industry_id: this.industry_id
        }
        api.updateIndustryTree(obj).then((res) => {
          if (res.code === "200") {
            this.dialog.showEdit = false;
            this.$message.success("编辑成功");
            this.getList();
            this.handleAddClose()
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    handleAddClose(params) {
      this.industry_id = '';
      this.parent_industry_id = null;
      this.industry_name = '';
      this.dialog.showAdd = false;
      this.dialog.showEdit = false;
    },
    pageChange(pageOption) {
      this.pagination.current = pageOption.current
      this.pagination.pageSize = pageOption.pageSize
      this.getList()
    },
    getList(val) {
      if (val) {
        this.pagination.current = 1
        this.pagination.pageSize = 10
      }
      this.spinning = true
      api.getAllIndustryTree({
        ...this.search,
        page: this.pagination.current,
        size: this.pagination.pageSize
      })
        .then((res) => {
          this.spinning = false
          console.log(res, '==========>');
          this.table.data = res.data.list;
          this.pagination.total = res.data.total
        });
    },
    handleDelMenu(record) {
      api.deleteIndustryTree(record.industry_id).then((res) => {
        if (res.code === "200") {
          this.$message.success("删除成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    async handleEdit(record) {
      this.title = '编辑行业分类'
      this.dialog.showEdit = true;
      let res = await api.getindustryTree()
      this.industryTree = res.data
      this.industry_id = record.industry_id;
      this.parent_industry_id = record.parent_industry_id;
      this.industry_name = record.industry_name;
    },
  },
};
</script>
<style scoped lang="scss">
.productName {
  display: -webkit-box;
  /* 需要对老版本的WebKit浏览器做兼容 */
  display: -ms-box;
  /* 对IE10的支持 */
  display: box;
  -webkit-line-clamp: 1;
  /* 设置显示的行数 */
  -webkit-box-orient: vertical;
  /* 设置布局方向为垂直 */
  overflow: hidden;
  /* 隐藏超出的内容 */
  text-overflow: ellipsis;
}
</style>